<template>
  <div v-if="dialog && bankStatement">
    <v-dialog :value="dialog" persistent transition="dialog-bottom-transition">
      <v-card>
        <v-toolbar>
          <v-btn icon @click="$emit('onCloseDialog')">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>
            Detail
          </v-toolbar-title
          >
          <v-spacer></v-spacer>
        
        </v-toolbar>
        <Loading :visible="loading" />
        <v-card-text>
          <v-container>
            <v-form ref="form" v-model="valid" lazy-validation>
              <v-row>
                <v-col cols="3" style="margin-top:10px;">
                  <v-subheader>Account Player: </v-subheader>
                </v-col>
                <v-col cols="9">
                  <v-text-field
                  type="text"
                  disabled
                >
                <template #prepend-inner>
                  <div style="width:250px" v-if="bankStatement.bank_sender">
                    <v-img style="margin: auto 0" max-height="30" max-width="30" :src="`/images/${bankStatement.bank_sender.bank_id}.png`"/>
                    <v-row style="margin-left: 40px;margin-bottom: 0px;margin-top:-35px;">
                      <v-col cols="12" md="12" style="padding: 2px !important;">
                        {{ bankStatement.bank_sender.account_name }} 
                        {{ 
                        bankStatement.bank_sender.currency_type == 1 ? '($)' 
                        :bankStatement.bank_sender.currency_type == 2? '(៛)' 
                        :bankStatement.bank_sender.currency_type == 5? '(฿)' 
                        : bankStatement.bank_sender.currency_type == 4 ? '(đ)' 
                        : '' 
                        }} 
                      </v-col>
                      <v-col cols="12" md="12" style="padding: 2px !important;">
                        {{ bankStatement.bank_sender.account_number }}
                      </v-col>
                    </v-row>
                  </div>
                  <div style="width:250px" v-else>
                    <v-img style="margin: auto 0" max-height="30" max-width="30" src=""/>
                  </div>
                </template>
              </v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="3" style="margin-top:10px;">
                  <v-subheader>Account Agent: </v-subheader>
                </v-col>
                <v-col cols="9">
                  <v-text-field
                  type="text"
                  disabled
                >
                <template #prepend-inner>
                  <div style="width:250px" v-if="bankStatement.bank_reciever">
                    <v-img style="margin: auto 0" max-height="30" max-width="30" :src="`/images/${bankStatement.bank_reciever.bank_id}.png`"/>
                    <v-row style="margin-left: 40px;margin-bottom: 0px;margin-top:-35px;">
                      <v-col cols="12" md="12" style="padding: 2px !important;">
                        {{ bankStatement.bank_reciever.account_name }} 
                        {{ 
                        bankStatement.bank_reciever.currency_type == 1 ? '($)' 
                        :bankStatement.bank_reciever.currency_type == 2? '(៛)' 
                        :bankStatement.bank_reciever.currency_type == 5? '(฿)' 
                        : bankStatement.bank_reciever.currency_type == 4 ? '(đ)' 
                        : '' 
                        }} 
                      </v-col>
                      <v-col cols="12" md="12" style="padding: 2px !important;">
                        {{ bankStatement.bank_reciever.account_number }}
                      </v-col>
                    </v-row>
                  </div>
                  <div style="width:250px" v-else>
                    <v-img style="margin: auto 0" max-height="40" max-width="40" src=""/>
                  </div>
                </template>
                </v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="3" style="margin-top:10px;">
                  <v-subheader>Amount</v-subheader>
                </v-col>
                <v-col cols="9">
                  <v-text-field
                  type="text"
                  disabled
                  >
                  <template #prepend-inner>
                    <div style="width:250px">
                      <v-row style="margin-left: 12px;margin-top: 0px;">
                      <v-col cols="12" md="12" style="padding: 2px !important;">
                        {{ 
                        bankStatement.currency_type == 1 ? '($)' 
                        :bankStatement.currency_type == 2? '(៛)' 
                        :bankStatement.currency_type == 3? '(฿)' 
                        : bankStatement.currency_type == 4 ? '(đ)' 
                        : '' 
                        }} 
                        
                        {{ currencyFormat(bankStatement.amount) }} 
                        
                      </v-col>
                    </v-row>
                    </div>
                  </template>
                </v-text-field>
                </v-col>
              </v-row>
              <!-- <v-row>
                <v-text-field
                   v-model="bankStatement.amount"
                  :label="$t('input.amount')"
                  type="text"
                  disabled
                ></v-text-field>
              </v-row> -->
              <v-row>
                <v-col cols="3" style="margin-top:10px;">
                  <v-subheader>Player Note</v-subheader>
                </v-col>
                <v-col cols="9">
                  <v-text-field
                  v-model="bankStatement.sender_note"
                  type="text"
                  disabled
                  ></v-text-field>
                </v-col>
              </v-row>
              <!-- <v-row>
                <v-text-field
                 v-model="bankStatement.sender_note"
                  :label="$t('input.sender_note')"
                  type="text"
                  disabled
                ></v-text-field>
              </v-row> -->
              <v-row>
                <v-col cols="3" style="margin-top:10px;">
                  <v-subheader>Agent Note</v-subheader>
                </v-col>
                <v-col cols="9">
                  <v-text-field
                  v-model="bankStatement.reciever_note"
                  type="text"
                  ></v-text-field>
                </v-col>
              </v-row>
              <!-- <v-row>
                <v-text-field
                 v-model="bankStatement.reciever_note"
                  :label="$t('input.reciever_note')"
                  type="text"
                ></v-text-field>
              </v-row> -->
              <v-row justify="space-around" v-if="bankStatement.status == 'PENDING'">
                <v-btn
                  depressed
                  color="primary"
                  @click="approvePlayer">
                  Approve
                </v-btn>

                <v-btn
                  depressed
                  color="error"
                  @click="rejectPlayer">
                  Reject
                </v-btn>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="approveDialog" persistent max-width="500px">
      <div class="card">
        <div class="card-header">
          <h4>Do you want to approve player?</h4>
        </div>
        <div class="card-body">

        </div>
        <div class="card-footer text-right">
          <v-row justify="space-around">
                <v-btn
                  depressed
                  color="primary"
                  @click="confirmApprove">
                  Yes Approve !
                </v-btn>

                <v-btn
                  depressed
                  color="error"
                  @click="_closeDialogApprove">
                  Close
                </v-btn>
              </v-row>
        </div>
      </div>
    </v-dialog>
  </div>
</template>
<style>
/* img {
  width: 30%;
  margin: auto;
  display: block;
  margin-bottom: 10px;
} */
button {
}
</style>

<script>
export default {
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    darkmode: {
      type: Boolean,
    },
    loading: {
      type: Boolean,
    },
    bankStatement: {
      type: Object,
      default: () => {
        return {
          "_id": null,
          "type": null,
          "sender_note": "",
          "sender_username": "",
          "reciever_username": "",
          "reciever_note": null,
          "currency_type": "",
          "bank_sender": null,
          "bank_reciever": null,
          "status": "",
          "is_reciever_read": null,
          "is_sender_read": null,
          "amount": 0,
          "promotion_percentage": 0,
          "send_date": null,
          "response_date": null
        };
      },
    },
    // rules: {
    //   type: Object,
    //   default: function () {
    //     return {
    //       user_name: [(v) => !!v || "Username is required"],
    //       amount_min: [(v) => !!v || "Amount Min is required"],
    //       amount_max: [(v) => !!v || "Amount Max is required"],
    //       betting_frequency: [(v) => !!v || "Betting Frequency is required"],
    //     };
    //   },
    // },
    isValidate: {
      type: Boolean,
      default: true,
    },
    
  },
  computed: {
    valid: {
      // getter
      get: function () {
        return this.isValidate;
      },
      // setter
      set: function (newValue) {
        this.$emit("update:isValidate", newValue);
      },
    },

    items () {
        return this.entries;
      },
  },
  data: () => {
    return {
      waiting: false,
      entries: [],
      isLoading: false,
      search: null,
      approveDialog:false
    };
  },
  methods: {

    async approvePlayer() {
      this.approveDialog = true;
    },
    async confirmApprove() {
      let data = this.bankStatement;
      this.$emit("approve", data);
    },

    _closeDialogApprove() {
      this.approveDialog = false;
    },
    async rejectPlayer() {
      let data = this.bankStatement;
      this.$emit("reject", data);
    },
    currencyFormat(num) {
      num = parseFloat(num);
      return num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
    },

    // async approvePlayer() {
    //   this.waiting = true;
    //   let data = {
    //     reciever_note: this.bankStatement.reciever_note, 
    //   };
    //   await this.$request
    //     .post({
    //       url: "/bankStatement/approved",
    //       data: data,
    //     })
    //     .then((res) => {
    //       if (res.data.code) {
    //         this.getData();
    //         this.waiting = false;
    //       }
    //     });
    // },

    // async rejectPlayer() {
    //   this.waiting = true;
    //   let data = {
    //     reciever_note: this.bankStatement.reciever_note, 
    //   };
    //   await this.$request
    //     .post({
    //       url: "/bankStatement/reject",
    //       data: data,
    //     })
    //     .then((res) => {
    //       if (res.data.code) {
    //         this.getData();
    //         this.waiting = false;
    //       }
    //     });
    // },
    

    validate() {
     
      if (this.$refs.form.validate()) {
        this.$emit("submit", this.bankStatement);
      }
    },


  },
  
};
</script>


